
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import DTCls from "@/utils/datetime";
import Pagination from "@/comp/Pagination/index.vue";
import { giftWallList, deleteGiftWall, saveGiftWall, giftWallPropAll } from "@/api/request/activity";

//组件
@Component({
  name: "GiftWall",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private propList: any[] = [];
  private listLoading: boolean = false;

  //请求参数
  private listQuery: any = {
    //页面数据
    page: 1, //分页
    page_size: 10, //每页行数

    //临时数据
    time: [],
    id_str: "",

    //请求参数
    id: 0, //活动类型
    name: "", //活动名称
    status: -1, //活动状态
    end_time: "", //创建时间结束
    start_time: "", //创建时间开始
  };

  //状态列表
  private statusList: any[] = [
    { status: -1, name: "全部状态" },
    { status: 0, name: "未开始" },
    { status: 1, name: "进行中" },
    { status: 2, name: "已结束" },
  ];

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
    };
  }

  //创建
  created() {
    //获取道具列表
    this.getPropList();

    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //时间赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }
    this.listQuery.id = Number(this.listQuery.id_str);

    //获取数据
    const { data } = await giftWallList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取道具列表
  private async getPropList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await giftWallPropAll({});

    //数据赋值
    this.propList = data.list;
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理创建
  private async handleCreate() {
    //数据赋值
    this.form = {
      //请求参数
      id: 0, //ID
      name: "", //礼物墙名字
      end_time: "", //结束时间
      start_time: "", //开始时间
      is_show_time: 0, //是否显示时间

      //礼物配置
      gift_conf: [],
    };
    this.isCreate = true;
    this.addPropList = [];

    //显示对话框
    this.dialogShow = true;
  }

  //处理编辑
  private handleEdit(row: any): void {
    //数据赋值
    this.form = {
      //请求参数
      id: row.id, //ID
      name: row.name, //礼物墙名字
      end_time: row.end_time_str, //结束时间
      start_time: row.start_time_str, //开始时间
      is_show_time: row.is_show_time, //是否显示时间

      //礼物配置
      gift_conf: GFunc.deepClone(row.gift_conf),
    };
    this.addPropList = GFunc.deepClone(row.gift_conf);
    this.isCreate = false;

    //显示对话框
    this.dialogShow = true;
  }

  //处理礼物配置
  private handleDetail(row: any): void {
    //数据赋值
    this.detailPropList = row.gift_conf;

    //显示界面
    this.detailDialogShow = true;
  }

  //处理删除
  private handleDelete(row: any): void {
    //显示提示
    this.$confirm("确定删除礼物<" + row.name + ">？", "警告", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //请求删除
        const res: any = await deleteGiftWall({ id: row.id });

        //删除成功
        if (res.status == 0) {
          //显示提示
          this.$message.success("删除成功");

          //获取数据
          this.getList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //------------------------------ 添加礼物 -----------------------------
  //定义变量
  private isCreate: boolean = false; //是否创建
  private addPropList: any[] = []; //添加道具列表
  private dialogShow: boolean = false; //显示随画框
  private dialogLoading: boolean = false; //对话框等待

  //表单数据
  private form: any = {
    //请求参数
    id: 0, //ID
    name: "", //礼物墙名字
    end_time: "", //结束时间
    start_time: "", //开始时间
    is_show_time: 0, //是否显示时间

    //礼物配置
    gift_conf: [],
  };

  //添加道具
  private addProp(): void {
    //数据赋值
    this.propOptions = [];
    for (var i: number = 0; i < this.propList.length; i++) {
      if (this.addPropList.indexOf(this.propList[i]) == -1) {
        this.propOptions.push(this.propList[i]);
      }
    }

    //显示对话框
    this.sendDialogShow = true;
  }

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogShow = false;
  }

  //处理创建
  private async createHandle() {
    //数据赋值
    var gift_conf: any[] = [];
    for (var i: number = 0; i < this.addPropList.length; i++) {
      //判断赋值
      if (Number(this.addPropList[i].need_num) > 0) {
        gift_conf.push({
          prop_id: this.addPropList[i].prop_id, //道具 ID
          sort: Number(this.addPropList[i].sort), //排序
          prop_name: this.addPropList[i].prop_name, //道具名称
          need_num: Number(this.addPropList[i].need_num), //需要数量
          diamond: this.addPropList[i].diamond,
          prop_name_list: this.addPropList[i].prop_name_list,
        });
      }
    }

    //判断返回
    if (this.form.name == "") return this.$message.error("请输入礼物名称");
    if (gift_conf.length == 0) return this.$message.error("请添加礼物道具");
    if (this.form.start_time == "") return this.$message.error("请选择礼物开始时间");
    if (this.form.end_time == "") return this.$message.error("请选择礼物结束时间");

    //数据赋值
    this.form.gift_conf = gift_conf;

    //显示等待
    this.dialogLoading = true;

    //超时隐藏
    setTimeout(() => {
      this.dialogLoading = false;
    }, 2000);

    //添加活动
    await saveGiftWall(this.form);

    //显示提示
    this.$notify({ title: "成功", message: "添加成功", type: "success", duration: 2000 });

    //隐藏界面
    this.dialogLoading = false;
    this.dialogShow = false;

    //重新获取数据
    this.getList();
  }

  //处理编辑
  private async updataHandle() {
    //数据赋值
    var gift_conf: any[] = [];
    for (var i: number = 0; i < this.addPropList.length; i++) {
      //判断赋值
      if (Number(this.addPropList[i].need_num) > 0) {
        gift_conf.push({
          prop_id: this.addPropList[i].prop_id, //道具ID
          sort: Number(this.addPropList[i].sort), //排序
          prop_name: this.addPropList[i].prop_name, //道具名称
          need_num: Number(this.addPropList[i].need_num), //需要数量
          diamond: this.addPropList[i].diamond,
          prop_name_list: this.addPropList[i].prop_name_list,
        });
      }
    }

    //判断返回
    if (this.form.name == "") return this.$message.error("请输入礼物名称");
    if (gift_conf.length == 0) return this.$message.error("请添加礼物道具");
    if (this.form.start_time == "") return this.$message.error("请选择礼物开始时间");
    if (this.form.end_time == "") return this.$message.error("请选择礼物结束时间");

    //数据赋值
    this.form.gift_conf = gift_conf;

    //显示等待
    this.dialogLoading = true;

    //超时隐藏
    setTimeout(() => {
      this.dialogLoading = false;
    }, 2000);

    //添加活动
    await saveGiftWall(this.form);

    //显示提示
    this.$notify({ title: "成功", message: "更新成功", type: "success", duration: 2000 });

    //隐藏界面
    this.dialogLoading = false;
    this.dialogShow = false;

    //重新获取数据
    this.getList();
  }

  //删除道具
  private delProp(index: any): void {
    //数据赋值
    this.addPropList.splice(index, 1);
  }

  //------------------------------ 道具列表 -----------------------------
  //定义变量
  private propOptions: any[] = [];
  private sendDialogShow: boolean = false; //显示对话框

  //取消按钮
  private btnSendCancel(): void {
    //隐藏界面
    this.sendDialogShow = false;
  }

  //添加道具
  private addGift(row: any): void {
    //排序赋值
    var sort: number = 1;
    if (this.addPropList.length > 0) {
      sort = Number(this.addPropList[this.addPropList.length - 1].sort) + 1;
    }

    //数据赋值
    this.addPropList.push({
      sort: sort, //排序
      need_num: 0, //需要数量
      prop_id: row.id, //道具 ID
      diamond: row.diamond,
      prop_name: row.propname, //道具名称
      prop_name_list: row.prop_name_list,
    });

    //隐藏界面
    this.sendDialogShow = false;
  }

  //------------------------------ 礼物详情 -----------------------------
  //定义变量
  private detailPropList: Array<any> = [];
  private detailDialogShow: boolean = false;
}
